.section-doctor {
	background-color: #f8f9fa;
	padding: 80px 0;
    margin-bottom: 7rem;
}
.profile-widget {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius:4px;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	padding: 15px;
    height: 460px;
}
.desigHeight{
    height: 30px;
}
.doc-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 4px;
    height: 200px;
}
.doc-img img {
    border-radius: 4px;
	transform: translateZ(0);
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: top;
    overflow: hidden;
}
.doc-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}

.pro-content {
    padding: 15px 0 0;
}
.pro-content .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
.profile-widget .pro-content .title a {
    display: inline-block;
}
.profile-widget .verified {
	color: #28a745;
	margin-left: 3px;
}
.profile-widget p.speciality {
    font-size: 13px;
    color: #757575;
    margin-bottom: 5px;
    min-height: 40px;
}
.view-profile-btn{
    border-color: #1977cc !important;
    color: #1977cc !important;
}
.view-profile-btn:hover{
    color: #fff !important;
    background-color: #1977cc !important;
}
.book-btn{
    background-color: #1977cc !important;
    border: none;
    color: #fff !important;    
}

.star {
	font-size: 14px !important;
}
.available-info {
    font-size: 13px;
    color: #757575;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}
.available-info li + li {
	margin-top: 5px;
}
.available-info li .icon {
	width: 22px;
}