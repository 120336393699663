.review-img img{
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.recomended{
    color: #20b420;
}
.swiper {
    width: inherit !important;
}
