.section-specialities {
	background-color: rgb(243, 241, 241);
	padding: 80px 0;
	margin: 7rem 0;
}

.specialities-slider {
	overflow:hidden;
}
@media (max-width: 600px) {
	.specialities-slider {
		overflow-x: scroll;
	}

	.specialities-slider ::-webkit-scrollbar {
		width: 10px;
	}
	.specialities-slider::-webkit-scrollbar {
		width: 20px;
	}
	.specialities-slider::-webkit-scrollbar-track {
		background-color: #ebeaea;
		border-radius: 100px;
	}
	.specialities-slider::-webkit-scrollbar-thumb {
		border-radius: 100px;
		border: 5px solid transparent;
		background-clip: content-box;
		background-color: #4588c4;
	}
}
.speicality-img {
	position: relative;
	height: 150px;
	box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
	border-radius: 100%;
	width: 150px;
	background: #fff;
	transition: 0.4s ease-in;
	left: 32px;
}

.speicality-item :hover {
	transform: scale(1.1);
	background: rgb(221, 221, 223);
}

.speicality-item :hover>img {
	background: none;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
}

.speicality-item:hover p {
	transform: scale(1.1);
	color: #1977cc;
}

.speicality-img img {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
}

.speicality-img span {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	bottom: 10px;
	right: 10px;
	box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	padding: 5px;
	background-color: #fff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}

.speicality-img span i {
	font-size: 15px;
	color: #0de0fe;
}

.speicality-item p {
	font-size: 16px;
	font-weight: 500;
	margin: 30px 0 0;
	transition: 0.4s ease-in;
}