.clinic-details ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.doc-department {color: #1977cc;}
.doc-department img{
    width: 1.5rem;
    margin-right: 2px;
}
.clinic-details ul li {
	display: inline-block;
	padding-right: 5px;
}
.clinic-details ul li:last-child {
	padding-right: 0;
}
.clinic-details ul li a {
	display: inline-block;
}
.clinic-details ul li a img {
	border-radius: 5px;
	width: 40px;
}

.clini-infos {
    margin-bottom: 15px;
}
.clini-infos ul {
	font-size: 14px;
	list-style: none;
	margin: 0;
	padding: 0;
}
.clini-infos ul li {
    display: block;
    line-height: 30px;
    color: #4E4852;
}
.clini-infos ul li i {
    font-size: 15px;
    min-width: 30px;
}
.clinic-booking a + a {
	margin-top: 15px;
}
.clinic-booking a {
    background-color: #fff;
    border: 2px solid #1977cc;
    border-radius: 4px;
    color: #1977cc;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 5px 10px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.clinic-booking a.view-pro-btn:hover, .clinic-booking a.view-pro-btn:focus {
	background: #1977cc; 
	color: #fff;
}
.clinic-booking a.apt-btn {
    background-color: #1977cc;
    color: #fff;
}
.clinic-booking a.apt-btn:hover, .clinic-booking a.apt-btn:focus {
	background-color: #1977cc;
	border-color: #1977cc;
	color: #fff;
}
.load-more {
	margin-bottom: 30px;
}
.overview-text {
    color: #2c4964;
    font-weight: 600;
}