

/* this file also include css for DashboardSidebarMenu */
.profile-sidebar {
    background-color: #f8f9fa;
	overflow: hidden;
}
/* dashboardsidebarMenu gets removedfrom main page when sreen is in mobile view  */
@media screen and (max-width: 767px) {
  .dashboard-menu {
    display: none;
  }
}
.dashboard-menu ul {
    color: #363636;
    font-size: 14px;
    line-height: 14px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}
.dashboard-menu ul li {
	line-height: inherit;
}
.dashboard-menu > ul > li {
	border-bottom:1px solid #dadada;
	position: relative;
}
.dashboard-menu > ul > li:last-child {
	border-bottom: 0;
}
.dashboard-menu ul li a span,
.dashboard-menu ul li a i {
	display: inline-block;
	vertical-align: middle;
}
.dashboard-menu > ul > li > a {
	color: #757575;
	display: block;
	padding: 16px 20px;
    transition: 0.2s ease-in;
}
.dashboard-menu > ul > li:hover > a,
.dashboard-menu > ul > li.active > a {
	color:#1977cc;
}
.dashboard-menu > ul > li:hover > a{
    transform: scale(1.05);
}
.active {
    color: #1977cc !important;
    background: #fff;
}

.dashboard-menu ul li a .icon {
    font-size: 16px;
    margin-right: 10px;
    width: 16px;
}
.profile-info {
    display: inline-block;
    margin: 0 0 15px;
    width: auto;
    padding: 8px;
    border-radius: 50%;
    
}
.profile-info img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 6px solid #efefef;
    object-fit: cover;
}
.profile-details h5{
    font-size: 18px;
    font-weight: 700;
}
.profile-details p{
    font-size: 13px;
}