.gallery{
    margin-top: 3rem;
    margin-bottom: 8rem;
}
.gallery-item {
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
}
.gallery-item img {
    transition: all ease-in-out 0.4s;
}
.gallery-item:hover img {
    transform: scale(1.1);
}
.galelry-lightbox {
    max-height: 200px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}
.galelry-lightbox img{
    object-fit:contain;
    object-position: top;
}
