.blog-title{
    color: #2c4964;
    font-weight: 600;
}
.categories-title h6, .categories-title .icon{
    color: #05335c;
}
.categories-title{
    color: #1977cc;
    cursor: pointer;
    transition: 0.6s ease-in;
}
.categories-title:hover > h6{
    color: #7054F2;
}
.categories-title:hover > .icon{
    color: #7054F2;
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  .embed-responsive::before {
    display: block;
    content: "";
  }
  
  .embed-responsive-16by9::before {
    padding-top: 56.25%; 
  }
  
  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .blogSize{
   height: 7rem;

  }
  .htext{
    font-size: 13px;
    font-weight: 600;
    opacity: .85;
  }
.ptext{
  font-size: 12px;
  font-weight: 400;
 opacity: .65;
 margin-top: -15px;
 margin-bottom: 8px;
}
.button-B{
  min-width: 70px;
  height: 25px;
  font-size: 11px;
  font-weight: 600;
  border:0px;
  background-color: white;
  color: rgb(131, 132, 132);


  
}
.button-B:hover{
  border-radius:20px;
  
  background: #c4c1c1;
  color: black;
}


.width{
  min-width: 500px;
}
.widthR{
  min-width: 400px;
}
  

@media only screen and (max-width: 400px) {
  .width{
    min-width: 200px;

    
}
.button-B{
  min-width: 25px;
  height: 13px;
  font-size: 8px;
  font-weight: 600;
  border:0px;
  background-color: white;
  color: rgb(131, 132, 132);


  
}
.button-B:hover{
  border-radius:20px;
  
  background: #c4c1c1;
  color: black;
}
}
@media only screen and (min-width: 400px) {
  .width{
    min-width: 250px;
    
}
  .widthR{
    min-width: 200px;
    
}


}
@media only screen and (min-width: 600px) {
  .width{
    min-width: 500px;
}
  .width{
    min-width: 400px;
}
}
@media only screen and (min-width: 800px) {
  .width{
    min-width: 700px;
}
  .widthR{
    min-width: 650px;
}
}
.addComment{
  font-size: 13px;
}
.txtC{
  color: #1977cc;
}
.col-sm-12.Blog-padding {
  padding-left: 12px;
  padding-right: 12px;
}

/* Specific padding for small screens */
@media (max-width: 767.98px) {
  .col-sm-12.Blog-padding {
    padding-left: 0; /* Adjust as needed */
    padding-right: 0; /* Adjust as needed */
  }
}