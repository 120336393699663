.clinic-list-container {
    margin-bottom: 1rem;
    border-radius: 5px;
}

.clinic-list-container div {
    padding: 0.75rem;
    transition: background-color 0.3s ease;
}

.clinic-list-container div:last-child {
    border-bottom: none;
}

.clinic-list-container div:hover {
    background-color: rgb(235, 235, 249);
}

.clinic-list-container div span {
    font-size: larger;
    margin-right: 2rem;
}

.clinic-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}

.clinic-list-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.clinic-edit,
.clinic-delete {
    cursor: pointer;
}