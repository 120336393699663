.nav-popover h5 {
  font-size: 18px;
  margin-bottom: 0;
}
.nav-popover a {
  margin: 0 0;
}

.profileImage img {
  height: 30px;
  border-radius: 50%;
  width: 30px;
  object-fit: cover;
  object-position: top;
  margin-left: 5px;
  margin-right: 5px;
  border: 2px #1977cc solid;
  cursor: pointer;
}
/* mobile view dashboard */
@media screen and (max-width: 767px) {
  .profileImage img {
    display: none;
  }
}
.hideTopHeader {
  display: none !important;
  transition: 1s !important;
}
.stickyHeader {
  top: 0 !important;
  transition: 1ms !important;
}
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  top: 40px;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}

#header.header-scrolled {
  top: 0;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #2c4964;
}

#header .logo img {
  max-height: 40px;
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 8px 0 8px 20px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #2c4964;
  white-space: nowrap;
  transition: 0.3s;
  border-bottom: 2px solid #fff;
  padding: 5px 2px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #1977cc;
  border-color: #1977cc;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 20px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #082744;
  border: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #1977cc;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #2c4964;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  margin-left: 5px;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  #navbar ul {
    display: none;
  }
}

.appointment-btn {
    margin-right: 10px;
    margin-left:10px;
  background: #1977cc;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}

.appointment-btn:hover {
  background: #166ab5;
  color: #fff;
}

@media (max-width: 768px) {
  .appointment-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

.mobile-menu-nav {
  list-style: none;
  font-size: 1.2rem;
  color: #1977cc !important;
}
.mobile-menu-nav .icon {
  font-size: 1.3rem;
  margin-right: 15px;
}
.mobile-menu-nav li {
  margin-bottom: 10px;
  padding: 5px;
}
.mobile-menu-nav li a {
  padding: 5px;
}
.ant-drawer-body {
  background-color: #d2d2ff;
}

/* DASHBOARD BUTTON ADDED FOR MOBILE VERSION */
@media screen and (min-width: 991px) {
  .dashboardButton {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .dashboardButton {
    display: inline;
  }
}

/* DESIGN THE SIDE-BAR ON CLICK */
.custom-drawer .ant-drawer-body {
  background-color: #f0f0f0;
  color: #333;
}
.custom-drawer .mobile-menu-nav li a {
  color: #333;
}
.custom-drawer .mobile-menu-nav li a:hover,
.custom-drawer .mobile-menu-nav li a.active {
  color: #007bff;
}
