.clinic-and-times-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.clinic-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    max-width: 500px;
}

.times-container {
    flex: 2;
    padding-left: 2rem;
}

/* Modal styles */
.hours-info {
    margin-bottom: 1.5rem;
    width: 100%;
}

.form-row {
    margin-bottom: 1rem;
}

/* Button container styles */
.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.add-more-button {
    width: auto !important;
    margin-top: 1rem;
}

/* Time slot container */
.time-slot-container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.time-slot-fields {
    flex: 1;
    width: 100%;
}

.delete-button {
    align-self: flex-end;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .clinic-and-times-container {
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
    }

    .clinic-container {
        max-width: 100%;
    }

    .times-container {
        padding-left: 0;
    }

    .time-slot-container {
        flex-direction: column;
        gap: 0.5rem;
    }

    .time-slot-fields .row {
        margin: 0;
    }

    .time-slot-fields .col-12 {
        padding: 0;
        margin-bottom: 0.5rem;
    }

    .form-group {
        margin-bottom: 0.5rem;
    }

    /* Adjust time picker width for mobile */
    .ant-picker {
        width: 100% !important;
    }

    .delete-button {
        width: 100%;
        margin: 0.5rem 0;
    }

    .button-container {
        padding: 0;
    }

    .add-more-button {
        width: 100% !important;
    }

    /* Modal padding adjustments */
    .ant-modal-body {
        padding: 12px !important;
    }

    .ant-modal-header {
        padding: 12px !important;
    }

    .ant-modal-footer {
        padding: 12px !important;
    }

    /* Ensure modal isn't too wide on mobile */
    .ant-modal {
        width: 90% !important;
        margin: 0 auto;
        top: 50% !important;
        transform: translateY(-50%) !important;
    }
}

/* For very small screens */
@media screen and (max-width: 380px) {
    .ant-modal {
        width: 95% !important;
    }

    .time-slot-fields .col-md-6 {
        width: 100%;
    }
}